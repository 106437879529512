import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ExplanationCard } from '../../components/ExplanationCard';
import useContentAnimation from '../../hooks/use-content-animation';

import { TheCookiesWeSetWrapper } from './styled';
interface InformationWeCollect {
	title: string;
	text: string[];
}

export const InformationWeCollect = ({ cards }: { cards: InformationWeCollect[] }) => {
	const { ref, controls } = useContentAnimation();
	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<TheCookiesWeSetWrapper ref={ref}></TheCookiesWeSetWrapper>
				{cards.map((item) => (
					<MotionDiv variants={fadeInUp} key={item.title}>
						<ExplanationCard title={item.title} text={item.text} key={item.title} />
					</MotionDiv>
				))}
			</motion.section>
		</ScContainer>
	);
};
